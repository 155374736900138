import React, { useState, useContext, useEffect, useRef } from "react";
import { useParams, useNavigate } from "react-router-dom";

import { motion, AnimatePresence } from "framer-motion";
import _ from "lodash";
import axios from "axios";

import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Stack";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";

import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";

import ManaIconsFormatted from "../components/decklists/ManaIconsFormatted";
import CardImage from "../components/featurematch/CardImage";
import Wrapper from "../components/layout/Wrapper";
import CopyToClipboardButton from "../components/widgets/CopyToClipboardButton";

import PlayerDataEditor from "../components/headtohead/PlayerDataEditor";

import ManaIcons from "../components/decklists/ManaIcons";
import DashboardTable from "../components/featurematch/DashboardTable";
import DeleteIcon from "@mui/icons-material/Delete";

import RoundByRoundViewer from "./viewers/RoundByRoundViewer";

//HOOKS
import useRoundByRound from "../hooks/useRoundByRound";

export default function RoundByRound({ parent = "" }) {
   let navigate = useNavigate();

   const [
      allRoundByRound,
      roundByRound,
      show,
      runTimestamp,
      {
         addRoundByRound,
         getRoundByRound,
         deleteRoundbyRound,
         getAllRoundByRound,
         roundByRoundViewerLoad,
         roundByRoundViewerShowHide,
         subscribeRoundByRoundViewer,
      },
   ] = useRoundByRound();

   const columnWidth = useRef();
   const [downloadVisibility, setDownloadVisibility] = useState(false);

   const iframeScale =
      columnWidth.current &&
      (parseInt(window.getComputedStyle(columnWidth.current).width) -
         parseInt(
            window
               .getComputedStyle(columnWidth.current)
               .getPropertyValue("padding-left")
         )) /
         1920;

   // WINDOW LISTENER
   const [windowSize, setWindowSize] = useState({});

   useEffect(() => {
      function handleResize() {
         setWindowSize({
            height: window.innerHeight,
            width: window.innerWidth,
         });
      }

      window.addEventListener("resize", handleResize);

      return (_) => {
         window.removeEventListener("resize", handleResize);
      };
   });

   useEffect(() => {
      setWindowSize({
         height: window.innerHeight,
         width: window.innerWidth,
      });
   }, []);

   useEffect(() => {
      getAllRoundByRound();

      const interval = setInterval(() => setTime(Date.now()), 60000);
      return () => {
         clearInterval(interval);
      };
   }, []);

   useEffect(() => {
      // console.log(allRoundByRound);
   }, [allRoundByRound]);

   // HIDE SHOW

   const handleLoadSlide = (e) => {
      roundByRoundViewerLoad(e.target.id);
   };

   const handleShowHide = (e) => {
      roundByRoundViewerShowHide(e.target.value);
   };

   // TIME
   const [time, setTime] = useState(Date.now());

   const timeSinceUpdate = (lastUpdate) => {
      if (lastUpdate) {
         let lastUpdateFormatted =
            lastUpdate.split(" ").slice(0, -1).join("T") + "Z";
         let date1 = new Date(lastUpdateFormatted);
         let date2 = new Date(time);
         let difference = date2.getTime() - date1.getTime();

         const secondsAgo = Math.max(0, Math.round(difference / 1000));
         const minutesAgo = Math.round(difference / 60000);

         return secondsAgo >= 60
            ? minutesAgo + " minute" + (minutesAgo > 1 ? "s" : "") + " ago"
            : secondsAgo + " seconds ago";
      }
   };

   const handleDelete = (id, title) => {
      if (id) {
         if (
            window.confirm("Are you sure you want to delete '" + title + "'?")
         ) {
            deleteRoundbyRound(id);
         }
      }
   };

   return (
      <Wrapper>
         <Typography
            variant='h3'
            component='div'
            sx={{ textTransform: "uppercase", mb: 2 }}
         >
            Round By Round Slides
         </Typography>

         {parent != "producer" && (
            <Grid container spacing={3} sx={{ mb: 8, maxWidth: 1525 }}>
               <Grid
                  item
                  xs={6}
                  ref={columnWidth}
                  sx={{
                     height: 1080 * iframeScale + 100,
                     position: "relative",
                  }}
               >
                  <Typography
                     variant='h5'
                     component='div'
                     sx={{ textTransform: "uppercase", mt: 4, mb: 2 }}
                  >
                     Round by Round Output
                  </Typography>

                  <Button
                     variant='contained'
                     color='warning'
                     sx={{ mr: 2, whiteSpace: "nowrap", px: 4 }}
                     value={"toggle"}
                     onClick={handleShowHide}
                     size='small'
                  >
                     TOGGLE
                  </Button>
                  <Button
                     variant='contained'
                     color='success'
                     sx={{ mr: 2, whiteSpace: "nowrap", px: 4 }}
                     value={"show"}
                     onClick={handleShowHide}
                     size='small'
                  >
                     SHOW
                  </Button>
                  <Button
                     variant='contained'
                     color='error'
                     sx={{ mr: 2, whiteSpace: "nowrap", px: 4 }}
                     value={"hide"}
                     onClick={handleShowHide}
                     size='small'
                  >
                     HIDE
                  </Button>

                  <Box
                     sx={{
                        position: "relative",
                        width: 1920,
                        height: 1080,
                        transform: "scale(" + iframeScale + ")",
                        transformOrigin: "top left",
                        overflow: "hidden",
                        border: "1px solid #333",
                        mt: 2,
                     }}
                     className='transparentBG'
                  >
                     <RoundByRoundViewer data={roundByRound} />
                  </Box>
               </Grid>
            </Grid>
         )}

         <Table variant='dashboard' sx={{ maxWidth: 1500 }}>
            <TableHead>
               <TableRow>
                  <TableCell>ID</TableCell>
                  <TableCell>Player</TableCell>
                  <TableCell>Last Updated</TableCell>
                  <TableCell align='right' width='200'>
                     {parent != "producer" && (
                        <Button
                           onClick={addRoundByRound}
                           variant='contained'
                           color='error'
                           fullWidth
                        >
                           + ADD
                        </Button>
                     )}
                  </TableCell>
               </TableRow>
            </TableHead>
            {allRoundByRound && (
               <TableBody>
                  {allRoundByRound.map((row) => (
                     <TableRow key={"slideRow" + row.id}>
                        <TableCell width='80'>{row.id}</TableCell>
                        <TableCell>{row.PlayerName}</TableCell>
                        <TableCell>{timeSinceUpdate(row.updatedAt)}</TableCell>
                        <TableCell align='right'>
                           <Stack direction='row' spacing={2}>
                              {parent != "producer" && (
                                 <Button
                                    variant='outlined'
                                    color='success'
                                    fullWidth
                                    id={row.id}
                                    onClick={handleLoadSlide}
                                 >
                                    LOAD
                                 </Button>
                              )}
                              <Button
                                 onClick={() =>
                                    navigate(
                                       "/" + parent + "/roundbyround/" + row.id
                                    )
                                 }
                                 variant='outlined'
                                 color='error'
                                 fullWidth
                              >
                                 {parent != "producer" ? "EDIT" : "PREVIEW"}
                              </Button>
                              {parent != "producer" && (
                                 <Button
                                    variant='outlined'
                                    color='error'
                                    fullWidth
                                    startIcon={<DeleteIcon />}
                                    onClick={() =>
                                       handleDelete(row.id, row.PlayerName)
                                    }
                                 >
                                    DELETE
                                 </Button>
                              )}
                           </Stack>
                        </TableCell>
                     </TableRow>
                  ))}
               </TableBody>
            )}
         </Table>
      </Wrapper>
   );
}
