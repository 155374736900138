import React, { useState, useContext, useEffect } from "react";
import { useParams } from "react-router-dom";

import { motion, AnimatePresence } from "framer-motion";
import _ from "lodash";
import axios from "axios";

import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Stack";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Modal from "@mui/material/Modal";

import WrapperIpad from "../layout/WrapperIpad";

import Bullets from "./Bullets";
import AdjustValue from "./AdjustValueIpad";
import ResetValue from "./ResetValue";

const SERVER_URL = process.env.REACT_APP_SERVER_URL;

const style = {
   position: "absolute",
   top: "50%",
   left: "50%",
   transform: "translate(-50%, -50%)",
   width: "90%",
   height: "80%",
   bgcolor: "#151515",
   border: "2px solid #333",
   boxShadow: 24,
   p: 10,
   textAlign: "center",
};

export default function PlayerIpadControls({
   gameState,
   player,
   tableId,
   playerName,
   callback,
}) {
   const [open, setOpen] = React.useState(false);
   const handleOpen = () => setOpen(true);
   const handleClose = () => setOpen(false);

   const [openPoison, setOpenPoison] = React.useState(false);
   const handleOpenPoison = () => setOpenPoison(true);
   const handleClosePoison = () => setOpenPoison(false);

   const [openMulligan, setOpenMulligan] = React.useState(false);
   const handleOpenMulligan = () => setOpenMulligan(true);
   const handleCloseMulligan = () => setOpenMulligan(false);

   const handleToggleDelirium = (playerNum) => {
      callback(tableId, {
         ["DeliriumP" + playerNum]: !gameState["DeliriumP" + playerNum],
      });
   };

   return (
      <>
         {gameState && player && (
            <>
               <AdjustValue
                  label='Life Total'
                  tableId={tableId}
                  property={"LifeTotalP" + player}
                  value={gameState["LifeTotalP" + player]}
                  amounts={[5, 1]}
                  callback={callback}
               />

               <Stack
                  direction='row'
                  sx={{ pt: 4, px: 4, position: "relative", mb: 2 }}
                  spacing={2}
               >
                  <Box sx={{ width: "100%" }}>
                     <Button
                        onClick={handleOpenMulligan}
                        variant='ipadButton'
                        sx={{ width: "100%", height: "13vh" }}
                     >
                        <Stack>
                           <Typography
                              variant='h5'
                              color='text.dark'
                              component='div'
                              sx={{ fontSize: "2vw" }}
                           >
                              Opening Hand
                           </Typography>
                           <Typography
                              variant='h6'
                              color='text.dark'
                              component='div'
                              sx={{
                                 fontSize: "2vw",
                              }}
                           >
                              {gameState["MulliganP" + player]
                                 ? gameState["MulliganP" + player]
                                 : ""}
                           </Typography>
                        </Stack>
                     </Button>
                  </Box>
                  <Box sx={{ width: "100%" }}>
                     <Button
                        onClick={handleOpenPoison}
                        variant='ipadButton'
                        sx={{ width: "100%", height: "13vh" }}
                     >
                        <Stack>
                           <Typography
                              variant='h5'
                              color='text.dark'
                              component='div'
                              sx={{ fontSize: "2vw" }}
                           >
                              POISON
                           </Typography>
                           <Typography
                              variant='h6'
                              color='text.dark'
                              component='div'
                              sx={{
                                 fontSize: "2vw",
                              }}
                           >
                              {gameState["PoisonP" + player]
                                 ? "-" + gameState["PoisonP" + player]
                                 : ""}
                           </Typography>
                        </Stack>
                     </Button>
                  </Box>
                  <Box sx={{ width: "100%" }}>
                     <Button
                        onClick={handleOpen}
                        variant='ipadButton'
                        sx={{ width: "100%", height: "13vh" }}
                     >
                        <Box>
                           <Typography
                              variant='h5'
                              color='text.dark'
                              component='div'
                              sx={{ mb: 1, fontSize: "2vw" }}
                           >
                              WINS
                           </Typography>
                           <Box
                              sx={{
                                 position: "relative",
                                 height: "3.5vh",
                              }}
                           >
                              <Bullets
                                 bestof={gameState["BestOf"]}
                                 wins={gameState["WinsP" + player]}
                                 callback={callback}
                              />
                           </Box>
                        </Box>
                     </Button>
                  </Box>
               </Stack>

               {/* <Box>
                  <Button
                     onClick={() => handleToggleDelirium(player)}
                     variant='ipadButton'
                     sx={{
                        height: "8vh",
                        width: "25%",
                        borderColor: gameState["DeliriumP" + player]
                           ? "text.orange"
                           : "text.dark",
                     }}
                  >
                     <Box>
                        <Typography
                           variant='h5'
                           color={
                              gameState["DeliriumP" + player]
                                 ? "text.orange"
                                 : "text.dark"
                           }
                           component='div'
                           sx={{ fontSize: "2vw" }}
                        >
                           DELIRIUM
                        </Typography>
                     </Box>
                  </Button>
               </Box> */}

               <Stack
                  justifyContent='center'
                  alignItems='center'
                  sx={{
                     position: "absolute",
                     textTransform: "uppercase",
                     bottom: 0,
                     height: "25vh",
                     width: "100%",
                     pb: 1,
                  }}
               >
                  <Typography
                     variant='h3'
                     color='text.dark'
                     component='div'
                     sx={{
                        px: 4,
                        fontSize: "3.5vw",
                     }}
                  >
                     {playerName ? playerName : "Player " + player}
                  </Typography>
               </Stack>

               <Modal
                  open={openMulligan}
                  onClose={handleCloseMulligan}
                  aria-labelledby='modal-modal-title'
                  aria-describedby='modal-modal-description'
               >
                  <Box sx={style}>
                     <Typography
                        variant='h3'
                        color='text.dark'
                        component='div'
                        sx={{
                           fontSize: "3vw",
                           textAlign: "center",
                           textTransform: "uppercase",
                        }}
                     >
                        {playerName ? playerName : "Player " + player}
                     </Typography>
                     <Typography
                        variant='h4'
                        color='text.dark'
                        component='div'
                        sx={{
                           fontSize: "4vw",
                           textAlign: "center",
                           textTransform: "uppercase",
                        }}
                     >
                        Opening Hand
                     </Typography>
                     <AdjustValue
                        label='Wins'
                        tableId={tableId}
                        property={"MulliganP" + player}
                        value={gameState["MulliganP" + player]}
                        amounts={[1]}
                        callback={callback}
                     />
                     <Button
                        variant='outlined'
                        color='success'
                        sx={{ fontSize: "3vw", mr: 4 }}
                        onClick={handleCloseMulligan}
                     >
                        CLOSE
                     </Button>
                     <ResetValue
                        tableId={tableId}
                        label='SET TO 7'
                        property={"MulliganP" + player}
                        value={7}
                        resetValue={7}
                        handleClose={handleCloseMulligan}
                        callback={callback}
                     />
                  </Box>
               </Modal>

               <Modal
                  open={openPoison}
                  onClose={handleClosePoison}
                  aria-labelledby='modal-modal-title'
                  aria-describedby='modal-modal-description'
               >
                  <Box sx={style}>
                     <Typography
                        variant='h3'
                        color='text.dark'
                        component='div'
                        sx={{
                           fontSize: "3vw",
                           textAlign: "center",
                           textTransform: "uppercase",
                        }}
                     >
                        {playerName ? playerName : "Player " + player}
                     </Typography>
                     <Typography
                        variant='h4'
                        color='text.dark'
                        component='div'
                        sx={{
                           fontSize: "4vw",
                           textAlign: "center",
                           textTransform: "uppercase",
                        }}
                     >
                        POISON
                     </Typography>
                     <AdjustValue
                        label='Wins'
                        tableId={tableId}
                        property={"PoisonP" + player}
                        value={gameState["PoisonP" + player]}
                        amounts={[1]}
                        callback={callback}
                     />
                     <Button
                        variant='outlined'
                        color='success'
                        sx={{ fontSize: "3vw", mr: 4 }}
                        onClick={handleClosePoison}
                     >
                        CLOSE
                     </Button>
                     <ResetValue
                        tableId={tableId}
                        property={"PoisonP" + player}
                        value={0}
                        handleClose={handleClosePoison}
                        callback={callback}
                     />
                  </Box>
               </Modal>

               <Modal
                  open={open}
                  onClose={handleClose}
                  aria-labelledby='modal-modal-title'
                  aria-describedby='modal-modal-description'
               >
                  <Box sx={style}>
                     <Typography
                        variant='h3'
                        color='text.dark'
                        component='div'
                        sx={{
                           fontSize: "3vw",
                           textAlign: "center",
                           textTransform: "uppercase",
                        }}
                     >
                        {playerName ? playerName : "Player " + player}
                     </Typography>
                     <Typography
                        variant='h4'
                        color='text.dark'
                        component='div'
                        sx={{
                           fontSize: "4vw",
                           textAlign: "center",
                           textTransform: "uppercase",
                        }}
                     >
                        WINS
                     </Typography>
                     <AdjustValue
                        label='Wins'
                        tableId={tableId}
                        property={"WinsP" + player}
                        value={gameState["WinsP" + player]}
                        amounts={[1]}
                        maxValue={gameState.BestOf}
                        callback={callback}
                     />
                     <Button
                        variant='outlined'
                        color='success'
                        sx={{ fontSize: "3vw" }}
                        onClick={handleClose}
                     >
                        CLOSE
                     </Button>
                  </Box>
               </Modal>
            </>
         )}
      </>
   );
}
