import React, { useState, useContext, useEffect, useRef } from "react";
import { useParams, useNavigate } from "react-router-dom";

import { motion, AnimatePresence } from "framer-motion";
import _ from "lodash";
import axios from "axios";

import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Stack";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";

import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";

import ManaIconsFormatted from "../components/decklists/ManaIconsFormatted";
import CardImage from "../components/featurematch/CardImage";
import Wrapper from "../components/layout/Wrapper";
import CopyToClipboardButton from "../components/widgets/CopyToClipboardButton";
import StandingsSelect from "../components/headtohead/StandingsSelect";

import PlayerDataEditor from "../components/headtohead/PlayerDataEditor";

import ManaIcons from "../components/decklists/ManaIcons";
import DashboardTable from "../components/featurematch/DashboardTable";
import NotificationWidget from "../components/widgets/NotificationWidget";
import TableHistoryModal from "../components/widgets/TableHistoryModal";

import DeleteIcon from "@mui/icons-material/Delete";

import HeadToHeadViewer from "./viewers/HeadToHeadViewer";

//HOOKS
import useHeadToHead from "../hooks/useHeadToHead";

// MODAL
import Modal from "@mui/material/Modal";

const style = {
   position: "absolute",
   top: "50%",
   left: "50%",
   transform: "translate(-50%, -50%)",
   width: "90%",
   height: "90%",
   overflow: "scroll",
   bgcolor: "background.paper",
   border: "2px solid #000",
   boxShadow: 24,
   p: 4,
};

export default function HeadToHead({ parent = "" }) {
   let navigate = useNavigate();

   const [
      notification,
      allHeadToHead,
      headToHead,
      show,
      runTimestamp,
      {
         update,
         addHeadToHead,
         deleteHeadToHead,
         getAllHeadToHead,
         getHeadToHead,
         headToHeadViewerLoad,
         headToHeadViewerShowHide,
         subscribeHeadToHeadViewer,
      },
   ] = useHeadToHead();

   const columnWidth = useRef();
   const [downloadVisibility, setDownloadVisibility] = useState(false);

   const iframeScale =
      columnWidth.current &&
      (parseInt(window.getComputedStyle(columnWidth.current).width) -
         parseInt(
            window
               .getComputedStyle(columnWidth.current)
               .getPropertyValue("padding-left")
         )) /
         1920;

   // WINDOW LISTENER
   const [windowSize, setWindowSize] = useState({});

   useEffect(() => {
      function handleResize() {
         setWindowSize({
            height: window.innerHeight,
            width: window.innerWidth,
         });
      }

      window.addEventListener("resize", handleResize);

      return (_) => {
         window.removeEventListener("resize", handleResize);
      };
   });

   useEffect(() => {
      setWindowSize({
         height: window.innerHeight,
         width: window.innerWidth,
      });
   }, []);

   useEffect(() => {
      getAllHeadToHead();
   }, []);

   useEffect(() => {
      // console.log(allHeadToHead);
   }, [allHeadToHead]);

   const handleLoadSlide = (viewerId, e) => {
      headToHeadViewerLoad(viewerId, e.target.id);
   };

   const handleShowHide = (viewerId, e) => {
      headToHeadViewerShowHide(viewerId, e.target.value);
   };

   const handleChangeStandings = (id, e) => {
      update(id, { [e.id]: e.value });
   };

   const handleDelete = (id, title) => {
      if (id) {
         if (
            window.confirm("Are you sure you want to delete '" + title + "'?")
         ) {
            deleteHeadToHead(id);
         }
      }
   };

   return (
      <Wrapper>
         <Typography
            variant='h3'
            component='div'
            sx={{ textTransform: "uppercase", mb: 2 }}
         >
            Head To Head Slides
         </Typography>

         {parent != "producer" && (
            <Grid container spacing={3} sx={{ mb: 8, maxWidth: 1525 }}>
               <Grid
                  item
                  xs={6}
                  ref={columnWidth}
                  sx={{
                     height: 1080 * iframeScale + 100,
                     position: "relative",
                  }}
               >
                  <Typography
                     variant='h5'
                     component='div'
                     sx={{ textTransform: "uppercase", mt: 4, mb: 2 }}
                  >
                     Head to Head Output 1
                  </Typography>

                  <Button
                     variant='contained'
                     color='warning'
                     sx={{ mr: 2, whiteSpace: "nowrap", px: 4 }}
                     value={"toggle"}
                     onClick={(e) => handleShowHide(1, e)}
                     size='small'
                  >
                     TOGGLE
                  </Button>
                  <Button
                     variant='contained'
                     color='success'
                     sx={{ mr: 2, whiteSpace: "nowrap", px: 4 }}
                     value={"show"}
                     onClick={(e) => handleShowHide(1, e)}
                     size='small'
                  >
                     SHOW
                  </Button>
                  <Button
                     variant='contained'
                     color='error'
                     sx={{ mr: 2, whiteSpace: "nowrap", px: 4 }}
                     value={"hide"}
                     onClick={(e) => handleShowHide(1, e)}
                     size='small'
                  >
                     HIDE
                  </Button>

                  <Box
                     sx={{
                        position: "relative",
                        width: 1920,
                        height: 1080,
                        transform: "scale(" + iframeScale + ")",
                        transformOrigin: "top left",
                        overflow: "hidden",
                        border: "1px solid #333",
                        mt: 2,
                     }}
                     className='transparentBG'
                  >
                     <HeadToHeadViewer previewViewerId={1} data={headToHead} />
                  </Box>
               </Grid>
               <Grid
                  item
                  xs={6}
                  ref={columnWidth}
                  sx={{
                     height: 1080 * iframeScale + 100,
                     position: "relative",
                  }}
               >
                  <Typography
                     variant='h5'
                     component='div'
                     sx={{ textTransform: "uppercase", mt: 4, mb: 2 }}
                  >
                     Head to Head Output 2
                  </Typography>

                  <Button
                     variant='contained'
                     color='warning'
                     sx={{ mr: 2, whiteSpace: "nowrap", px: 4 }}
                     value={"toggle"}
                     onClick={(e) => handleShowHide(2, e)}
                     size='small'
                  >
                     TOGGLE
                  </Button>
                  <Button
                     variant='contained'
                     color='success'
                     sx={{ mr: 2, whiteSpace: "nowrap", px: 4 }}
                     value={"show"}
                     onClick={(e) => handleShowHide(2, e)}
                     size='small'
                  >
                     SHOW
                  </Button>
                  <Button
                     variant='contained'
                     color='error'
                     sx={{ mr: 2, whiteSpace: "nowrap", px: 4 }}
                     value={"hide"}
                     onClick={(e) => handleShowHide(2, e)}
                     size='small'
                  >
                     HIDE
                  </Button>

                  <Box
                     sx={{
                        position: "relative",
                        width: 1920,
                        height: 1080,
                        transform: "scale(" + iframeScale + ")",
                        transformOrigin: "top left",
                        overflow: "hidden",
                        border: "1px solid #333",
                        mt: 2,
                     }}
                     className='transparentBG'
                  >
                     <HeadToHeadViewer previewViewerId={2} data={headToHead} />
                  </Box>
               </Grid>
            </Grid>
         )}

         <Table variant='dashboard' sx={{ maxWidth: 1500 }}>
            <TableHead>
               <TableRow>
                  <TableCell>ID</TableCell>
                  <TableCell>Players</TableCell>
                  <TableCell>Data Points</TableCell>
                  <TableCell>Record / Rank</TableCell>
                  <TableCell align='right' width='400'>
                     {parent != "producer" && (
                        <Button
                           onClick={addHeadToHead}
                           variant='contained'
                           color='error'
                        >
                           + ADD NEW
                        </Button>
                     )}
                     <TableHistoryModal callback={getAllHeadToHead} />
                  </TableCell>
               </TableRow>
            </TableHead>
            <TableBody>
               {allHeadToHead &&
                  allHeadToHead.map((row) => (
                     <TableRow key={"slideRow" + row.id}>
                        <TableCell width='80'>{row.id}</TableCell>
                        <TableCell>
                           {row.Player1 ? row.Player1.PlayerName : "-NONE-"}
                           <br />
                           <b style={{ color: "#999" }}>vs.</b>
                           <br />
                           {row.Player2 ? row.Player2.PlayerName : "-NONE-"}
                        </TableCell>
                        <TableCell>
                           {row.DataFields &&
                              row.DataFields.data.map((field, i) => (
                                 <React.Fragment
                                    key={"datfield-" + field + "-" + i}
                                 >
                                    {field.title && (
                                       <>
                                          {i + 1}: {field.title.toUpperCase()}
                                       </>
                                    )}
                                    <br />
                                 </React.Fragment>
                              ))}
                        </TableCell>
                        <TableCell>
                           <StandingsSelect
                              selected={row.StandingsId ? row.StandingsId : 0}
                              callback={(e) => handleChangeStandings(row.id, e)}
                           />
                        </TableCell>
                        <TableCell align='right' sx={{ whiteSpace: "nowrap" }}>
                           <Stack direction='row' spacing={1}>
                              {parent != "producer" && (
                                 <>
                                    <Button
                                       variant='outlined'
                                       color='success'
                                       fullWidth
                                       id={row.id}
                                       onClick={(e) => handleLoadSlide(1, e)}
                                    >
                                       LOAD 1
                                    </Button>
                                    <Button
                                       variant='outlined'
                                       color='success'
                                       fullWidth
                                       id={row.id}
                                       onClick={(e) => handleLoadSlide(2, e)}
                                    >
                                       LOAD 2
                                    </Button>
                                 </>
                              )}
                              <Button
                                 onClick={() =>
                                    navigate(
                                       "/" + parent + "/headtohead/" + row.id
                                    )
                                 }
                                 variant='outlined'
                                 color='warning'
                                 fullWidth
                              >
                                 {parent != "producer" ? "EDIT" : "PREVIEW"}
                              </Button>

                              {parent != "producer" && (
                                 <Button
                                    variant='outlined'
                                    color='error'
                                    fullWidth
                                    startIcon={<DeleteIcon />}
                                    onClick={() =>
                                       handleDelete(row.id, "Slide " + row.id)
                                    }
                                 >
                                    DELETE
                                 </Button>
                              )}
                           </Stack>
                        </TableCell>
                     </TableRow>
                  ))}
            </TableBody>
         </Table>

         {notification && (
            <NotificationWidget
               notification={notification.message}
               key={"notification" + notification.date}
            />
         )}
      </Wrapper>
   );
}
