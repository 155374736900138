import { useState, useMemo, useContext, useEffect } from "react";
import { SocketContext } from "../context/socket";

import axios from "axios";
import _ from "lodash";

export default function useCardsInHand() {
   const socket = useContext(SocketContext);

   const [allDecklists, setAllDecklists] = useState();
   const [decklist, setDecklist] = useState();
   const [allCardNames, setAllCardNames] = useState();
   const [cardsInHand, setCardsInHand] = useState();
   const [notification, setNotification] = useState();

   const [gameState, setGameState] = useState();

   const handlers = useMemo(
      () => ({
         getDecklist: (decklistId) => {
            if (decklistId != "search") {
               socket.emit("getDecklist", decklistId, (response) => {
                  setDecklist(response);
               });
            } else {
               setDecklist();
            }
         },
         getCardsInHand: (tableId, playerPosition) => {
            socket.emit(
               "getCardsInHand",
               tableId,
               playerPosition,
               (response) => {
                  setCardsInHand(response);
               }
            );
         },
         addToHand: (
            tableId,
            playerPosition,
            card,
            order,
            cardsInHandCount
         ) => {
            // if (cardsInHandCount < 7) {
            let cardToAdd = {
               Name: card.CardName,
               Mana: card.CardManaCost,
               FileName: card.FileName,
            };

            socket.emit(
               "addToHand",
               tableId,
               playerPosition,
               cardToAdd,
               order,
               (response) => {
                  setCardsInHand(response);
               }
            );
            // } else {
            //    setNotification({
            //       date: Date.now(),
            //       message: "Sorry, only 7 cards are allowed in hand!",
            //    });
            // }
         },
         removeCardInHand: (tableId, playerPosition, id) => {
            socket.emit(
               "removeCardInHand",
               tableId,
               playerPosition,
               id,
               (response) => {
                  setCardsInHand(response);
               }
            );
         },
         getAllCardNames: () => {
            socket.emit("getAllCardNames", (response) => {
               setAllCardNames(response);
            });
         },
         getFeatureMatchDecklists: (tableId) => {
            socket.emit("getFeatureMatchDecklists", tableId, (response) => {
               setAllDecklists(response);
            });
         },
      }),
      [socket]
   );

   return [
      allDecklists,
      decklist,
      allCardNames,
      cardsInHand,
      notification,
      handlers,
   ];
}
