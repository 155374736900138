import React, { useState, useContext, useEffect } from "react";

import { motion, AnimatePresence } from "framer-motion";
import _ from "lodash";

import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Stack";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import { useTheme } from "@mui/material/styles";

import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableBody from "@mui/material/TableBody";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";

import CheckActive from "./CheckActive";
import PlayerSelect from "./PlayerSelect";

const SERVER_URL = process.env.REACT_APP_SERVER_URL;

export default function PlayerData({ data, dataFields }) {
   const theme = useTheme();

   const forceLineBreak = (text) => {
      if (text) {
         let textArray = text.split(" ");
         let divider = textArray.length === 2 ? "<br />" : " ";
         let response = textArray.join(divider);
         return <div dangerouslySetInnerHTML={{ __html: response }} />;
      }
   };

   const lang = (text) => {
      switch (text) {
         case "STARTED_IN":
            return "Started In";
            break;
         case "TOP_FINISHES":
            return "Top Finishes";
            break;
         case "PTS_THIS_SEASON":
            return "2024&nbsp;Pro Tours";
            break;
         case "PREFERRED_PRONOUNS":
            return "Pronouns";
            break;
         default:
            return text;
      }
   };

   return (
      <>
         <Stack direction='row' sx={{ width: "100%" }}>
            {data &&
               dataFields &&
               dataFields.data.map((row, i) => (
                  <React.Fragment key={"bulletpoint-" + i}>
                     {data[row.field] && (
                        <Stack
                           justifyContent='center'
                           alignItems='center'
                           spacing={2}
                           sx={{ width: "33.3%" }}
                        >
                           <Typography
                              variant='h1profilevalue'
                              component='div'
                              sx={{
                                 width: "95%",
                              }}
                           >
                              {data[row.field]}
                           </Typography>
                        </Stack>
                     )}
                  </React.Fragment>
               ))}
         </Stack>

         <Stack direction='row' sx={{ width: "100%" }}>
            {data &&
               dataFields &&
               dataFields.data.map((row, i) => (
                  <React.Fragment key={"bulletpointTitle-" + i}>
                     {data[row.field] && (
                        <Stack
                           justifyContent='flex-start'
                           alignItems='center'
                           sx={{ width: "33.3%" }}
                        >
                           <Typography
                              variant='h2profiletitle'
                              component='div'
                              sx={{
                                 width: "100%",
                                 textTransform: "uppercase",
                              }}
                           >
                              {forceLineBreak(lang(row.title))}
                           </Typography>
                        </Stack>
                     )}
                  </React.Fragment>
               ))}
         </Stack>
      </>
   );
}
