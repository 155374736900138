import React, { useState, useRef, useContext, useEffect } from "react";
import { useParams } from "react-router-dom";
import { useTheme } from "@mui/material/styles";

import { motion, AnimatePresence } from "framer-motion";
import _ from "lodash";
import axios from "axios";

import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Stack";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";

import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableBody from "@mui/material/TableBody";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";

import WrapperViewer from "../../components/layout/WrapperViewer";
import RoundByRoundDay from "../../components/roundbyround/RoundByRoundDay";

const SERVER_URL = process.env.REACT_APP_SERVER_URL;
const TOURNAMENT_FORMAT_KEY = process.env.REACT_APP_TOURNAMENT_FORMAT_KEY;

const draftRounds = 3;
const formatRounds = 4;
const roundsPerDay = draftRounds + formatRounds;

export default function RoundByRoundPreview({ data, show }) {
   const theme = useTheme();
   const [roundByRound, setRoundByRound] = useState();

   useEffect(() => {
      if (data && data.Results && data.Results.length > 0) {
         const updatedResults = data.Results.map((row) => {
            if (row.OpponentMetadata) {
               const metaData = JSON.parse(row.OpponentMetadata);
               row.name = metaData.FirstName + " " + metaData.LastName;

               row.decklist = metaData[TOURNAMENT_FORMAT_KEY + " Deck"]
                  ? metaData[TOURNAMENT_FORMAT_KEY + " Deck"]
                  : row.OpponentDecklistName;
            } else {
               row.name = row.OpponentName;
               row.decklist = row.OpponentDecklistName;
            }

            return row;
         });

         setRoundByRound({
            ...data,
            data: {
               ...data.data,
               PlayerData: { ...data, Results: updatedResults },
            },
         });
      }
   }, [data]);

   const getDeckName = (format) => {
      const decklist = _.find(roundByRound.Decklists, function (o) {
         return format === o.Format;
      });

      return decklist ? decklist.Name : format;
   };

   return (
      <WrapperViewer>
         <Box
            sx={{
               position: "absolute",
               top: 40,
               right: 100,
               height: 200,
            }}
         >
            <img src={SERVER_URL + "/images/ui/logo-800.png"} width='125' />
         </Box>

         <AnimatePresence>
            {roundByRound && roundByRound.data && show && (
               <>
                  <Stack
                     sx={{
                        position: "absolute",
                        top: 40,
                        left: 250,
                        height: 200,
                     }}
                     justifyContent='center'
                     key={roundByRound.PlayerId}
                     component={motion.div}
                     initial={{ opacity: 0 }}
                     animate={{
                        opacity: 1,
                     }}
                     exit={{
                        opacity: 0,
                     }}
                     transition={{
                        easing: "easeOut",
                        duration: 0.5,
                     }}
                  >
                     <Typography
                        variant='h1Viewer'
                        component='div'
                        sx={{ textTransform: "uppercase" }}
                     >
                        <Box
                           sx={{
                              p: 2,
                              top: -20,
                              left: -20,
                              position: "relative",
                           }}
                        >
                           {roundByRound &&
                              roundByRound.PlayerName.toUpperCase()}
                        </Box>
                     </Typography>
                     <Stack
                        direction='row'
                        alignItems='center'
                        sx={{
                           top: -30,
                           position: "relative",
                        }}
                     >
                        {_.sortBy(roundByRound.globals.json).map(
                           (global, i) => (
                              <React.Fragment key={"global-" + i}>
                                 <Stack
                                    sx={{
                                       backgroundColor:
                                          theme.palette.text.orange,
                                       color: theme.palette.text.primary,
                                       fontFamily: "GothamNarrow-Bold",
                                       borderRadius: 100,
                                       width: 40,
                                       height: 40,
                                       fontSize: 24,
                                       pl: 0.3,
                                       pt: 0.2,
                                       mr: 2,
                                    }}
                                    width='100%'
                                    alignItems='center'
                                    justifyContent='center'
                                 >
                                    {global[0]}
                                 </Stack>
                                 <Typography
                                    variant='h4Viewer'
                                    component='div'
                                    sx={{
                                       textTransform: "uppercase",
                                       pt: 0.3,
                                       mr: 6,
                                    }}
                                 >
                                    {global}
                                 </Typography>
                              </React.Fragment>
                           )
                        )}
                     </Stack>
                  </Stack>

                  {roundByRound &&
                     roundByRound.data &&
                     roundByRound.Results &&
                     roundByRound.Results.length > 0 && (
                        <Stack
                           spacing={3}
                           sx={{
                              position: "absolute",
                              width: 1620,
                              top: 230,
                              left: 100,
                           }}
                           key={roundByRound.id}
                           component={motion.div}
                           initial={{ opacity: 0 }}
                           animate={{
                              opacity: 1,
                           }}
                           exit={{
                              opacity: 0,
                           }}
                           transition={{
                              easing: "easeOut",
                              duration: 0.5,
                           }}
                        >
                           <RoundByRoundDay
                              day={1}
                              draftRounds={draftRounds}
                              formatRounds={formatRounds}
                              roundsPerDay={draftRounds + formatRounds}
                              formatLetter={roundByRound.globals.json[1][0]}
                              results={roundByRound.Results.slice(
                                 0,
                                 roundsPerDay
                              )}
                           />

                           <RoundByRoundDay
                              day={2}
                              draftRounds={draftRounds}
                              formatRounds={formatRounds}
                              roundsPerDay={draftRounds + formatRounds}
                              formatLetter={roundByRound.globals.json[1][0]}
                              results={roundByRound.Results.slice(
                                 roundsPerDay,
                                 roundsPerDay * 2
                              )}
                           />
                        </Stack>
                     )}
               </>
            )}
         </AnimatePresence>
      </WrapperViewer>
   );
}
