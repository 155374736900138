import React, { useState, useContext, useEffect, useRef } from "react";
import { useParams } from "react-router-dom";

import { motion, AnimatePresence } from "framer-motion";
import _ from "lodash";
import axios from "axios";

import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Stack";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Modal from "@mui/material/Modal";

import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";

import DebugOverlay from "../widgets/DebugOverlay";
import WrapperViewer from "../layout/WrapperViewer";

import PlayerSelect from "./PlayerSelect";
import PlayerDecklistSelect from "./PlayerDecklistSelect";
import AdjustRoundTitle from "./AdjustRoundTitle";

import AdjustValue from "./AdjustValue";
import BestOfValue from "./BestOfValue";

import CopyToClipboardButton from "../../components/widgets/CopyToClipboardButton";
import FeatureMatchViewer from "../../routes/viewers/FeatureMatch";
import FeatureMatchOverlay from "../../routes/viewers/FeatureMatchOverlay";

//HOOKS
import useFeatureMatchHandler from "../../hooks/useFeatureMatchHandler";

const SERVER_URL = process.env.REACT_APP_SERVER_URL;

const style = {
   position: "absolute",
   top: "50%",
   left: "50%",
   width: "80%",
   transform: "translate(-50%, -50%)",
   bgcolor: "#151515",
   border: "2px solid #333",
   boxShadow: 24,
   p: 4,
   textAlign: "center",
};

export default function DashboardTable({ tableId, callback }) {
   const columnWidth = useRef();
   const [previewOverlay, setPreviewOverlay] = useState(false);

   const iframeScale =
      columnWidth.current &&
      (parseInt(window.getComputedStyle(columnWidth.current).width) -
         parseInt(
            window
               .getComputedStyle(columnWidth.current)
               .getPropertyValue("padding-left")
         )) /
         1920;

   // WINDOW LISTENER
   const [windowSize, setWindowSize] = useState({});

   useEffect(() => {
      function handleResize() {
         setWindowSize({
            height: window.innerHeight,
            width: window.innerWidth,
         });
      }

      window.addEventListener("resize", handleResize);

      return (_) => {
         window.removeEventListener("resize", handleResize);
      };
   });

   useEffect(() => {
      setWindowSize({
         height: window.innerHeight,
         width: window.innerWidth,
      });
   }, []);

   // USE SOCKETS HOOK
   const [
      players,
      allPlayers,
      gameState,
      cardsInHand,
      update,
      lastUpdated,
      saveRequired,
      needsSave,
      {
         subscribeFeatureMatch,
         refreshFMPhotos,
         getAllPlayers,
         saveUpdates,
         cancelUpdates,
      },
   ] = useFeatureMatchHandler();

   // MODAL
   const [openRoundTitle, setOpenRoundTitle] = useState(false);
   const handleOpenRoundTitle = () => setOpenRoundTitle(true);
   const handleCloseRoundTitle = () => setOpenRoundTitle(false);

   useEffect(() => {
      subscribeFeatureMatch(tableId);
      getAllPlayers();
   }, []);

   useEffect(() => {
      // console.log(allPlayers);
   }, [allPlayers]);

   useEffect(() => {
      // console.log(players);
   }, [players]);

   const handleTextfieldKeyPress = (e) => {
      if (e.keyCode === 13) {
         e.target.blur();
      }
   };

   const updateRoundTitle = (value) => {
      update(tableId, { ["RoundTitle"]: value }, true);
      handleCloseRoundTitle();
   };

   const handleResetAll = () => {
      update(
         tableId,
         {
            ["LifeTotalP1"]: 20,
            ["LifeTotalP2"]: 20,
            ["PoisonP1"]: 0,
            ["PoisonP2"]: 0,
         },
         true
      );
   };

   return (
      <Box sx={{ p: 4 }}>
         <Grid container spacing={4}>
            <Grid item xs={2}>
               {gameState && (
                  <Stack spacing={4}>
                     <BestOfValue
                        label='Best Of'
                        tableId={tableId}
                        property='BestOf'
                        value={gameState.BestOf}
                        amounts={[3, 5, 7, 9]}
                        callback={update}
                     />
                     <Stack spacing={2}>
                        {tableId == 1 && (
                           <Button
                              onClick={() =>
                                 update(
                                    tableId,
                                    {
                                       visibleCardsInHand:
                                          !gameState.visibleCardsInHand,
                                    },
                                    true
                                 )
                              }
                              variant={
                                 gameState.visibleCardsInHand
                                    ? "contained"
                                    : "outlined"
                              }
                              color={
                                 gameState.visibleCardsInHand
                                    ? "success"
                                    : "alert"
                              }
                           >
                              Cards In Hand
                           </Button>
                        )}
                        <Button
                           onClick={() =>
                              update(
                                 tableId,
                                 {
                                    visibleScoreBars:
                                       !gameState.visibleScoreBars,
                                 },
                                 true
                              )
                           }
                           variant={
                              gameState.visibleScoreBars
                                 ? "contained"
                                 : "outlined"
                           }
                           color={
                              gameState.visibleScoreBars ? "success" : "alert"
                           }
                        >
                           Score Bars
                        </Button>
                        <Button
                           onClick={() =>
                              update(
                                 tableId,
                                 {
                                    visibleProfileImages:
                                       !gameState.visibleProfileImages,
                                 },
                                 true
                              )
                           }
                           variant={
                              gameState.visibleProfileImages
                                 ? "contained"
                                 : "outlined"
                           }
                           color={
                              gameState.visibleProfileImages
                                 ? "success"
                                 : "alert"
                           }
                        >
                           Profile Images
                        </Button>
                        <Button
                           onClick={() =>
                              update(
                                 tableId,
                                 {
                                    visibleWins: !gameState.visibleWins,
                                 },
                                 true
                              )
                           }
                           variant={
                              gameState.visibleWins ? "contained" : "outlined"
                           }
                           color={gameState.visibleWins ? "success" : "alert"}
                        >
                           Game Wins
                        </Button>
                        <Button
                           onClick={() =>
                              update(
                                 tableId,
                                 {
                                    visibleStandings:
                                       !gameState.visibleStandings,
                                 },
                                 true
                              )
                           }
                           variant={
                              gameState.visibleStandings
                                 ? "contained"
                                 : "outlined"
                           }
                           color={
                              gameState.visibleStandings ? "success" : "alert"
                           }
                        >
                           Standings
                        </Button>
                        <Button
                           onClick={() =>
                              update(
                                 tableId,
                                 {
                                    visibleTopLogo: !gameState.visibleTopLogo,
                                 },
                                 true
                              )
                           }
                           variant={
                              gameState.visibleTopLogo
                                 ? "contained"
                                 : "outlined"
                           }
                           color={
                              gameState.visibleTopLogo ? "success" : "alert"
                           }
                        >
                           Top Logo
                        </Button>
                        <Button
                           onClick={() =>
                              update(
                                 tableId,
                                 {
                                    DeliriumP1: !gameState.DeliriumP1,
                                 },
                                 true
                              )
                           }
                           variant={
                              gameState.DeliriumP1
                                 ? "contained"
                                 : "outlined"
                           }
                           color={
                              gameState.DeliriumP1 ? "success" : "alert"
                           }
                        >
                           P1 Delirium
                        </Button>
                        <Button
                           onClick={() =>
                              update(
                                 tableId,
                                 {
                                    DeliriumP2: !gameState.DeliriumP2,
                                 },
                                 true
                              )
                           }
                           variant={
                              gameState.DeliriumP2
                                 ? "contained"
                                 : "outlined"
                           }
                           color={
                              gameState.DeliriumP2 ? "success" : "alert"
                           }
                        >
                           P2 Delirium
                        </Button>
                        <Button
                           onClick={() =>
                              update(
                                 tableId,
                                 {
                                    visibleCountry: !gameState.visibleCountry,
                                 },
                                 true
                              )
                           }
                           variant={
                              gameState.visibleCountry
                                 ? "contained"
                                 : "outlined"
                           }
                           color={
                              gameState.visibleCountry ? "success" : "alert"
                           }
                        >
                           Country
                        </Button>
                        <Button
                           onClick={() => setPreviewOverlay(!previewOverlay)}
                           variant={previewOverlay ? "contained" : "outlined"}
                           color='warning'
                        >
                           Preview Card Overlay
                        </Button>
                     </Stack>

                     {tableId == 1 && cardsInHand && (
                        <Stack spacing={1}>
                           <Typography
                              variant='h5'
                              color='text.light'
                              component='div'
                           >
                              Cards In Hand - Preview
                           </Typography>
                           <Typography
                              variant='h5'
                              color='text.dark'
                              component='div'
                           >
                              Player 1
                           </Typography>
                           <Table variant='fmCardsInHand'>
                              <TableBody>
                                 {cardsInHand["1"] &&
                                    cardsInHand["1"].map((row, i) => (
                                       <TableRow
                                          key={
                                             "cardRowP1" +
                                             i +
                                             "-" +
                                             row.card.Name
                                          }
                                       >
                                          <TableCell component='th' scope='row'>
                                             {row.card.Name}
                                          </TableCell>
                                       </TableRow>
                                    ))}
                              </TableBody>
                           </Table>

                           <Typography
                              variant='h5'
                              color='text.dark'
                              component='div'
                           >
                              Player 2
                           </Typography>
                           <Table variant='fmCardsInHand'>
                              <TableBody>
                                 {cardsInHand["2"] &&
                                    cardsInHand["2"].map((row, i) => (
                                       <TableRow
                                          key={
                                             "cardRowP2" +
                                             i +
                                             "-" +
                                             row.card.Name
                                          }
                                       >
                                          <TableCell component='th' scope='row'>
                                             {row.card.Name}
                                          </TableCell>
                                       </TableRow>
                                    ))}
                              </TableBody>
                           </Table>
                        </Stack>
                     )}
                  </Stack>
               )}
            </Grid>
            <Grid
               item
               xs={10}
               sx={{ height: 1080 * iframeScale, mb: 4 }}
               ref={columnWidth}
            >
               <Box
                  iframeScale={iframeScale}
                  sx={{
                     position: "relative",
                     width: 1920,
                     height: 1080,
                     transform: "scale(" + iframeScale + ")",
                     transformOrigin: "top left",
                     overflow: "hidden",
                     border: "1px solid #333",
                  }}
                  className='transparentBG'
               >
                  {previewOverlay && (
                     <Box
                        sx={{
                           position: "absolute",
                           top: 0,
                           left: 0,
                           zIndex: 1000,
                           outline: "orange 2px solid",
                           outlineOffset: -4,
                           width: 1920,
                           height: 1080,
                        }}
                     >
                        <FeatureMatchOverlay />
                     </Box>
                  )}
                  <FeatureMatchViewer tableId={tableId} />
               </Box>
            </Grid>
            <Grid item xs={2}></Grid>
            <Grid item xs={10}>
               <Grid container spacing={4}>
                  <Grid item>
                     <CopyToClipboardButton
                        text={"/viewer/featurematch/" + tableId}
                     />
                  </Grid>
                  <Grid item xs={3}>
                     <Button
                        variant='outlined'
                        color='secondary'
                        onClick={() => refreshFMPhotos(tableId)}
                     >
                        REFRESH PLAYER DATA
                     </Button>
                  </Grid>
                  <Grid item xs sx={{ textAlign: "right" }}>
                     <Button
                        onClick={handleOpenRoundTitle}
                        variant='outlined'
                        color='warning'
                     >
                        Update Round Title
                     </Button>
                  </Grid>
               </Grid>
            </Grid>

            <Grid item xs={12}>
               <Box sx={{ position: "relative" }}>
                  {saveRequired && (
                     <Stack direction='row' spacing={2}>
                        <Button
                           variant='contained'
                           color='error'
                           sx={{
                              fontSize: "2vw",
                           }}
                           onClick={() => saveUpdates(tableId)}
                           fullWidth
                        >
                           SAVE CHANGES
                        </Button>

                        <Button
                           variant='contained'
                           color='secondary'
                           sx={{
                              fontSize: "2vw",
                           }}
                           onClick={() => cancelUpdates(tableId)}
                        >
                           CANCEL
                        </Button>
                     </Stack>
                  )}
               </Box>
            </Grid>

            <Grid item xs={12}>
               {gameState && (
                  <Grid
                     container
                     sx={{ backgroundColor: "#ccc", width: "100%" }}
                  >
                     <Grid item xs={6} sx={{ textAlign: "center", py: 4 }}>
                        <Typography
                           variant='h4'
                           color='text.dark'
                           component='div'
                           sx={{ mb: 2 }}
                        >
                           Player 1
                        </Typography>

                        <PlayerSelect
                           playerPosition={1}
                           allPlayersList={allPlayers}
                           selected={gameState.PlayerIdP1}
                           tableId={tableId}
                           callback={update}
                        />
                        <Box sx={{ mt: 1 }}>
                           <PlayerDecklistSelect
                              playerPosition={1}
                              playerData={_.find(allPlayers, (player) => {
                                 return player.id === gameState.PlayerIdP1;
                              })}
                              selected={gameState.PlayerDecklistP1}
                              tableId={tableId}
                              callback={update}
                           />
                        </Box>

                        <AdjustValue
                           label='Life Total'
                           tableId={tableId}
                           property='LifeTotalP1'
                           value={gameState.LifeTotalP1}
                           amounts={[5, 1]}
                           resetAmount={20}
                           callback={update}
                        />

                        <AdjustValue
                           label='Poison'
                           tableId={tableId}
                           property='PoisonP1'
                           value={gameState.PoisonP1}
                           amounts={[1]}
                           resetAmount={0}
                           callback={update}
                        />

                        <AdjustValue
                           label='Wins'
                           tableId={tableId}
                           property='WinsP1'
                           value={gameState.WinsP1}
                           amounts={[1]}
                           maxValue={gameState.BestOf}
                           resetAmount={0}
                           hideReset
                           callback={update}
                        />
                     </Grid>
                     <Grid item xs={6} sx={{ textAlign: "center", py: 4 }}>
                        <Typography
                           variant='h4'
                           color='text.dark'
                           component='div'
                           sx={{ mb: 2 }}
                        >
                           Player 2
                        </Typography>

                        <PlayerSelect
                           playerPosition={2}
                           allPlayersList={allPlayers}
                           selected={gameState.PlayerIdP2}
                           tableId={tableId}
                           callback={update}
                        />

                        <Box sx={{ mt: 1 }}>
                           <PlayerDecklistSelect
                              playerPosition={2}
                              playerData={_.find(allPlayers, (player) => {
                                 return player.id === gameState.PlayerIdP2;
                              })}
                              selected={gameState.PlayerDecklistP2}
                              tableId={tableId}
                              callback={update}
                           />
                        </Box>

                        <AdjustValue
                           label='Life Total'
                           tableId={tableId}
                           property='LifeTotalP2'
                           value={gameState.LifeTotalP2}
                           amounts={[5, 1]}
                           resetAmount={20}
                           callback={update}
                        />

                        <AdjustValue
                           label='Poison'
                           tableId={tableId}
                           property='PoisonP2'
                           value={gameState.PoisonP2}
                           amounts={[1]}
                           resetAmount={0}
                           callback={update}
                        />

                        <AdjustValue
                           label='Wins'
                           tableId={tableId}
                           property='WinsP2'
                           value={gameState.WinsP2}
                           amounts={[1]}
                           maxValue={gameState.BestOf}
                           resetAmount={0}
                           hideReset
                           callback={update}
                        />
                     </Grid>
                     <Grid xs={12}>
                        <Button
                           sx={{ width: "100%", borderRadius: 0 }}
                           variant='contained'
                           color='error'
                           onClick={handleResetAll}
                        >
                           RESET SCOREBAR
                        </Button>
                     </Grid>
                  </Grid>
               )}
               )}
            </Grid>
         </Grid>

         <Modal open={openRoundTitle} onClose={handleCloseRoundTitle}>
            <Box sx={style}>
               <AdjustRoundTitle
                  save={updateRoundTitle}
                  cancel={handleCloseRoundTitle}
               />
            </Box>
         </Modal>
      </Box>
   );
}
