import React, { useState, useContext, useEffect } from "react";
import { useParams } from "react-router-dom";
import { useTheme } from "@mui/material/styles";

import { motion, AnimatePresence } from "framer-motion";
import _ from "lodash";
import axios from "axios";

import HeadToHeadPreview from "./HeadToHeadPreview";

//HOOKS
import useHeadToHead from "../../hooks/useHeadToHead";

const SERVER_URL = process.env.REACT_APP_SERVER_URL;

export default function HeadToHeadViewer({ previewViewerId = null }) {
   const { viewerId } = useParams();

   const [
      notification,
      allHeadToHead,
      headToHead,
      show,
      runTimestamp,
      { subscribeHeadToHeadViewer },
   ] = useHeadToHead();

   useEffect(() => {
      subscribeHeadToHeadViewer(previewViewerId ? previewViewerId : viewerId);
   }, []);

   useEffect(() => {
      // console.log(headToHead);
   }, [headToHead]);

   return (
      <>
         {headToHead && (
            <>
               <HeadToHeadPreview
                  preview={false}
                  iframeScale={1}
                  data={headToHead}
                  show={show}
                  key={runTimestamp}
               />
            </>
         )}
      </>
   );
}
