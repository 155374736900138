import React, { useState, useEffect } from "react";
import { Box, Stack, Button } from "@mui/material";
import CustomLeaderboardViewer from "../../routes/viewers/CustomLeaderboardViewer";

export default function CustomLeaderboardPreview({
   viewerId,
   handleUpdatePage,
   iframeScale,
}) {
   return (
      <Stack>
         <Stack direction='row' spacing={2} sx={{ mb: 2 }}>
            <Button
               variant='outlined'
               color='secondary'
               sx={{ ml: 4, mr: 2, whiteSpace: "nowrap", px: 4 }}
               value={"first"}
               onClick={(e) => handleUpdatePage(viewerId, e)}
               size='small'
            >
               {"<<"}
            </Button>
            <Button
               variant='contained'
               color='secondary'
               sx={{ mr: 2, whiteSpace: "nowrap", px: 4 }}
               value={"prev"}
               onClick={(e) => handleUpdatePage(viewerId, e)}
               size='small'
            >
               {"<"}
            </Button>
            <Button
               variant='contained'
               color='secondary'
               sx={{ mr: 2, whiteSpace: "nowrap", px: 4 }}
               value={"next"}
               onClick={(e) => handleUpdatePage(viewerId, e)}
               size='small'
            >
               {">"}
            </Button>
         </Stack>

         <Box
            sx={{
               position: "relative",
               width: 1920,
               height: 1080,
               transform: "scale(" + iframeScale + ")",
               transformOrigin: "top left",
               overflow: "hidden",
               border: "1px solid #333",
            }}
            className='transparentBG'
         >
            <CustomLeaderboardViewer previewViewerId={viewerId} />
         </Box>
      </Stack>
   );
}
