import React, { useState, useContext, useEffect, useRef } from "react";
import { useParams } from "react-router-dom";

import { motion, AnimatePresence } from "framer-motion";
import _ from "lodash";
import axios from "axios";

import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Stack";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";

import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";

import ManaIconsFormatted from "../components/decklists/ManaIconsFormatted";
import CardImage from "../components/featurematch/CardImage";
import Wrapper from "../components/layout/Wrapper";
import CopyToClipboardButton from "../components/widgets/CopyToClipboardButton";

import PlayerDataEditor from "../components/headtohead/PlayerDataEditor";
import StandingsSelect from "../components/headtohead/StandingsSelect";

import ManaIcons from "../components/decklists/ManaIcons";
import DashboardTable from "../components/featurematch/DashboardTable";
import NotificationWidget from "../components/widgets/NotificationWidget";
import Downloader from "../components/widgets/Downloader";

import HeadToHeadPreview from "./viewers/HeadToHeadPreview";

//HOOKS
import useHeadToHead from "../hooks/useHeadToHead";
import useDatabaseManagement from "../hooks/useDatabaseManagement";

export default function HeadToHeadEditor({ parent = "" }) {
   let { id } = useParams();

   const columnWidth = useRef();

   const iframeScale =
      columnWidth.current &&
      (parseInt(window.getComputedStyle(columnWidth.current).width) -
         parseInt(
            window
               .getComputedStyle(columnWidth.current)
               .getPropertyValue("padding-left")
         )) /
         1920;

   // WINDOW LISTENER
   const [windowSize, setWindowSize] = useState({});

   useEffect(() => {
      function handleResize() {
         setWindowSize({
            height: window.innerHeight,
            width: window.innerWidth,
         });
      }

      window.addEventListener("resize", handleResize);

      return (_) => {
         window.removeEventListener("resize", handleResize);
      };
   });

   useEffect(() => {
      setWindowSize({
         height: window.innerHeight,
         width: window.innerWidth,
      });
   }, []);

   const [
      notification,
      allHeadToHead,
      headToHead,
      show,
      runTimestamp,
      { update, getAllHeadToHead, getHeadToHead, headToHeadViewerLoad },
   ] = useHeadToHead();

   const [dbLog, previewData, dbNotification, { getPlayerData }] =
      useDatabaseManagement();

   useEffect(() => {
      getHeadToHead(id);
   }, []);

   useEffect(() => {
      // console.log(headToHead);
   }, [headToHead]);

   const handleGetPlayerData = (e) => {
      getPlayerData(e.target.id).then((response) => {
         getHeadToHead(id);
      });
   };

   const handlePlayerChange = (updates) => {
      let newDataFields = headToHead.DataFields;
      let clearDecks = {
         DataFields: {
            ...newDataFields,
            PlayerDecklistP1: "",
            PlayerDecklistP2: "",
         },
      };
      update(id, { ...updates, ...clearDecks });
   };

   const handleLoadSlide = (e) => {
      headToHeadViewerLoad(e.target.id);
   };

   const handleChangeStandings = (e) => {
      update(id, { [e.id]: e.value });
   };

   const handleDataFieldUpdate = (e) => {
      let newDataFields = headToHead.DataFields;

      if (isNaN(e.target.id)) {
         update(id, {
            DataFields: {
               ...newDataFields,
               [e.target.id]: e.target.value,
            },
         });
      } else {
         newDataFields.data[e.target.id] = {
            title: e.target.value,
            field: e.target.value,
         };
         update(id, { DataFields: newDataFields });
      }
   };

   return (
      <Wrapper>
         <Stack direction='row' alignItems='center' sx={{ mb: 2 }}>
            <Typography
               variant='h3'
               component='div'
               sx={{ textTransform: "uppercase", width: "100%" }}
            >
               Head To Head
            </Typography>

            {headToHead && headToHead.Player1 && headToHead.Player2 && (
               <Downloader
                  enabled={true}
                  downloadName={
                     "HeadToHead_" +
                     (headToHead.Player1 &&
                     headToHead.Player1.PlayerNameLastFirst
                        ? headToHead.Player1.PlayerNameLastFirst.split(
                             ","
                          )[0].replace(/[^A-Za-z0-9]/g, "")
                        : "none") +
                     "_vs_" +
                     (headToHead.Player2 &&
                     headToHead.Player2.PlayerNameLastFirst
                        ? headToHead.Player2.PlayerNameLastFirst.split(
                             ","
                          )[0].replace(/[^A-Za-z0-9]/g, "")
                        : "none") +
                     "_" +
                     Date.now()
                  }
               >
                  <HeadToHeadPreview data={headToHead} show={true} />
               </Downloader>
            )}
         </Stack>

         <Grid container spacing={3} sx={{ mb: 8 }}>
            <Grid
               item
               xs={6}
               ref={columnWidth}
               sx={{ height: 1080 * iframeScale, position: "relative" }}
            >
               <Box
                  sx={{
                     position: "relative",
                     width: 1920,
                     height: 1080,
                     transform: "scale(" + iframeScale + ")",
                     transformOrigin: "top left",
                     overflow: "hidden",
                     border: "1px solid #333",
                  }}
                  className='transparentBG'
               >
                  <HeadToHeadPreview data={headToHead} show={true} />
               </Box>
            </Grid>
            {headToHead && (
               <Grid item xs={6}>
                  <Stack
                     sx={{
                        backgroundColor: "#fff",
                        textAlign: "center",
                        p: 4,
                     }}
                  >
                     <Box sx={{ mb: 4 }}>
                        <Typography
                           variant='h5'
                           color='text.dark'
                           component='div'
                           sx={{
                              textAlign: "center",
                              textTransform: "uppercase",
                              mb: 2,
                           }}
                        >
                           Selected Data Fields
                        </Typography>
                        <Stack spacing={1}>
                           {headToHead.DataFields &&
                              headToHead.DataFields.data.map((row, i) => (
                                 <Box key={"field" + i}>
                                    {row.title}
                                    {row.field && (
                                       <>
                                          {parent != "producer" && (
                                             <Button
                                                size='small'
                                                variant='outlined'
                                                color='error'
                                                sx={{ ml: 2 }}
                                                id={i}
                                                value=''
                                                onClick={handleDataFieldUpdate}
                                             >
                                                Clear
                                             </Button>
                                          )}
                                       </>
                                    )}
                                 </Box>
                              ))}
                        </Stack>
                     </Box>

                     <Box sx={{ mb: 4 }}>
                        <Typography
                           variant='h5'
                           color='text.dark'
                           component='div'
                           sx={{
                              textAlign: "center",
                              textTransform: "uppercase",
                              mb: 2,
                           }}
                        >
                           Selected Decklists
                        </Typography>
                        <Stack spacing={1}>
                           {headToHead.Player1Decklist && (
                              <Box>
                                 Player 1: {headToHead.Player1Decklist.Format} |{" "}
                                 {headToHead.Player1Decklist.Archetype}
                                 {parent != "producer" && (
                                    <Button
                                       size='small'
                                       variant='outlined'
                                       color='error'
                                       sx={{ ml: 2 }}
                                       id={"PlayerDecklistP1"}
                                       value=''
                                       onClick={handleDataFieldUpdate}
                                    >
                                       Clear
                                    </Button>
                                 )}
                              </Box>
                           )}

                           {headToHead.Player2Decklist && (
                              <Box>
                                 Player 2: {headToHead.Player2Decklist.Format} |{" "}
                                 {headToHead.Player2Decklist.Archetype}
                                 {parent != "producer" && (
                                    <Button
                                       size='small'
                                       variant='outlined'
                                       color='error'
                                       sx={{ ml: 2 }}
                                       id={"PlayerDecklistP2"}
                                       value=''
                                       onClick={handleDataFieldUpdate}
                                    >
                                       Clear
                                    </Button>
                                 )}
                              </Box>
                           )}
                        </Stack>
                     </Box>

                     <Box>
                        <Typography
                           variant='h5'
                           color='text.dark'
                           component='div'
                           sx={{
                              textAlign: "center",
                              textTransform: "uppercase",
                           }}
                        >
                           Standings Data
                        </Typography>

                        <StandingsSelect
                           selected={
                              headToHead.StandingsId
                                 ? headToHead.StandingsId
                                 : 0
                           }
                           callback={handleChangeStandings}
                        />
                     </Box>
                  </Stack>
               </Grid>
            )}
         </Grid>

         {headToHead && parent != "producer" && (
            <Stack>
               <Grid container spacing={4}>
                  <Grid item xs={6} sx={{ textAlign: "center" }}>
                     <PlayerDataEditor
                        playerPosition={1}
                        playerId={headToHead.PlayerIdP1}
                        activeDataFields={headToHead.DataFields}
                        data={headToHead.Player1Data}
                        handleDataFieldUpdate={handleDataFieldUpdate}
                        handlePlayerChange={handlePlayerChange}
                        handleGetPlayerData={handleGetPlayerData}
                     />
                  </Grid>
                  <Grid item xs={6} sx={{ textAlign: "center" }}>
                     <PlayerDataEditor
                        playerPosition={2}
                        playerId={headToHead.PlayerIdP2}
                        activeDataFields={headToHead.DataFields}
                        data={headToHead.Player2Data}
                        handleDataFieldUpdate={handleDataFieldUpdate}
                        handlePlayerChange={handlePlayerChange}
                        handleGetPlayerData={handleGetPlayerData}
                     />
                  </Grid>
               </Grid>
            </Stack>
         )}

         {notification && (
            <NotificationWidget
               notification={notification.message}
               key={"notification" + notification.date}
            />
         )}
      </Wrapper>
   );
}
