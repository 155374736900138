import { useState, useMemo, useContext, useEffect } from "react";
import { SocketContext } from "../context/socket";

import axios from "axios";
import _ from "lodash";

export default function useHeadToHead() {
   const socket = useContext(SocketContext);

   const [notification, setNotification] = useState();
   const [allHeadToHead, setAllHeadToHead] = useState();
   const [headToHead, setHeadToHead] = useState();
   const [show, setShow] = useState(true);
   const [runTimestamp, setRunTimestamp] = useState(Date.now());

   const handlers = useMemo(
      () => ({
         update: (id, updates) => {
            socket.emit("updateHeadToHead", id, updates, (response) => {
               handlers.getHeadToHead(id);
               handlers.getAllHeadToHead();
            });
         },
         headToHeadViewerLoad: (viewerId, id) => {
            socket.emit("headToHeadViewerLoad", viewerId, id);
         },
         getHeadToHead: (id) => {
            socket.emit("getHeadToHead", id, (response) => {
               setHeadToHead(response);
            });
         },
         getAllHeadToHead: () => {
            socket.emit("getAllHeadToHead", (response) => {
               setAllHeadToHead(response);
            });
         },
         deleteHeadToHead: (id) => {
            socket.emit("deleteHeadToHead", id, (response) => {
               setAllHeadToHead(response);
            });
         },
         addHeadToHead: (callback) => {
            socket.emit("addHeadToHead", (response) => {
               handlers.getAllHeadToHead();
            });
         },
         headToHeadViewerShowHide: (viewerId, action) => {
            socket.emit("headToHeadViewerShowHide", viewerId, action);
         },
         subscribeHeadToHeadViewer: (viewerId) => {
            socket.emit("subscribeHeadToHeadViewer", viewerId, (response) => {
               console.log(response);
            });

            socket.on("headToHeadViewerLoad", (endpoint, id) => {
               // console.log(endpoint, id);
               if (endpoint == viewerId) {
                  handlers.getHeadToHead(id);
               }
            });

            socket.on("headToHeadViewerShowHide", (endpoint, action) => {
               // console.log(endpoint, action);
               if (endpoint == viewerId) {
                  switch (action) {
                     case "show":
                        setRunTimestamp(Date.now());
                        setShow(true);
                        break;
                     case "hide":
                        setShow((prevState) => {
                           return false;
                        });
                        break;
                     case "toggle":
                        setShow((prevState) => {
                           return !prevState;
                        });
                        break;
                  }
               }
            });
         },
      }),
      [socket]
   );

   return [
      notification,
      allHeadToHead,
      headToHead,
      show,
      runTimestamp,
      handlers,
   ];
}
