import React, { useState, useRef, useContext, useEffect } from "react";
import { useParams } from "react-router-dom";
import { useTheme } from "@mui/material/styles";

import { motion, AnimatePresence } from "framer-motion";
import _ from "lodash";
import axios from "axios";

import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Stack";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";

import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableBody from "@mui/material/TableBody";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";

import WrapperViewer from "../../components/layout/WrapperViewer";
import DebugOverlay from "../../components/widgets/DebugOverlay";

import RoundByRoundPreview from "../viewers/RoundByRoundPreview";

//HOOKS
import useRoundByRound from "../../hooks/useRoundByRound";

const SERVER_URL = process.env.REACT_APP_SERVER_URL;

export default function RoundByRoundViewer() {
   // USE HOOK
   const [
      allRoundByRound,
      roundByRound,
      show,
      runTimestamp,
      { subscribeRoundByRoundViewer },
   ] = useRoundByRound();

   useEffect(() => {
      subscribeRoundByRoundViewer();
      // console.log("Subscribed!");
   }, []);

   useEffect(() => {
      // console.log(roundByRound);
   }, [roundByRound]);

   return (
      <>
         {console.log("test")}
         {roundByRound && (
            <RoundByRoundPreview
               preview={false}
               iframeScale={1}
               data={roundByRound}
               show={show}
               key={runTimestamp}
            />
         )}
      </>
   );
}
