import React, { useState, useContext, useEffect, useRef } from "react";
import { useParams, useNavigate } from "react-router-dom";

import { motion, AnimatePresence } from "framer-motion";
import _ from "lodash";
import axios from "axios";

import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Stack";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";

import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";

import Downloader from "../components/widgets/Downloader";
import Wrapper from "../components/layout/Wrapper";
import CopyToClipboardButton from "../components/widgets/CopyToClipboardButton";
import StandingsSelect from "../components/playerprofiles/StandingsSelect";

import SelectInput from "../components/inputs/SelectInput";
import TextInput from "../components/inputs/TextInput";
import PlayerSelect from "../components/customleaderboard/PlayerSelect";

import NotificationWidget from "../components/widgets/NotificationWidget";

import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import DeleteIcon from "@mui/icons-material/Delete";

import CustomLeaderboardPreview from "../components/customleaderboard/CustomLeaderboardPreview";

//HOOKS
import useCustomLeaderboard from "../hooks/useCustomLeaderboard";

// MODAL
import Modal from "@mui/material/Modal";

const style = {
   position: "absolute",
   top: "50%",
   left: "50%",
   transform: "translate(-50%, -50%)",
   width: "90%",
   height: "auto",
   maxHeight: "90%",
   overflow: "auto",
   bgcolor: "#444",
   border: "2px solid #000",
   boxShadow: 24,
   p: 4,
};

export default function CustomLeaderboard({ parent = "" }) {
   let navigate = useNavigate();

   const [
      notification,
      all,
      activeData,
      show,
      runTimestamp,
      allPlayers,
      currentPage,
      {
         update,
         add,
         deleteLeaderboard,
         getAll,
         get,
         viewerLoad,
         viewerShowHide,
         subscribeViewer,
         loadAllPlayers,
         setPage,
      },
   ] = useCustomLeaderboard();

   const [editRow, setEditRow] = useState(null);

   useEffect(() => {
      subscribeViewer(1);
      loadAllPlayers();
   }, []);

   // useEffect(() => {
   //    console.log(activeData);
   // }, [activeData]);

   const columnWidth = useRef();
   const [downloadVisibility, setDownloadVisibility] = useState(false);

   const iframeScale =
      columnWidth.current &&
      (parseInt(window.getComputedStyle(columnWidth.current).width) -
         parseInt(
            window
               .getComputedStyle(columnWidth.current)
               .getPropertyValue("padding-left")
         )) /
         1920;

   // WINDOW LISTENER
   const [windowSize, setWindowSize] = useState({});

   useEffect(() => {
      function handleResize() {
         setWindowSize({
            height: window.innerHeight,
            width: window.innerWidth,
         });
      }

      window.addEventListener("resize", handleResize);

      return (_) => {
         window.removeEventListener("resize", handleResize);
      };
   });

   useEffect(() => {
      setWindowSize({
         height: window.innerHeight,
         width: window.innerWidth,
      });
   }, []);

   useEffect(() => {
      getAll();
   }, []);

   useEffect(() => {
      // console.log(all);
   }, [all]);

   const handleLoadSlide = (id, e) => {
      viewerLoad(id, e.target.id);
   };

   const handleShowHide = (id, e) => {
      viewerShowHide(id, e.target.value);
   };

   const handleUpdatePage = (id, e) => {
      setPage(id, e.target.value);
   };

   const handleChangeStandings = (id, e) => {
      update(id, { [e.id]: e.value });
   };

   const handleDelete = (id, title) => {
      if (id) {
         if (
            window.confirm("Are you sure you want to delete '" + title + "'?")
         ) {
            deleteLeaderboard(id);
         }
      }
   };

   return (
      <Wrapper>
         <Stack
            direction='row'
            alignItems='center'
            justifyContent='space-between'
         >
            <Typography
               variant='h3'
               component='div'
               sx={{ textTransform: "uppercase", mb: 2 }}
            >
               Storyboards
            </Typography>

            {/* <Downloader
               enabled={activeData ? true : false}
               downloadName={
                  activeData && activeData.rows && activeData.rows.length > 0
                     ? "Leaderboard-" +
                       activeData.Title +
                       "-" +
                       currentPage +
                       "_" +
                       Date.now()
                     : ""
               }
            >
               <CustomLeaderboardPreview
                  currentPage={currentPage}
                  show={show}
                  activeData={activeData}
                  preview={true}
               />
            </Downloader> */}
         </Stack>

         <Stack direction='row' spacing={4} height={1080 * iframeScale + 100}>
            <Box
               width='40%'
               ref={columnWidth}
               sx={{
                  height: 1080 * iframeScale,
                  position: "relative",
                  mb: 4,
               }}
            >
               <CustomLeaderboardPreview
                  viewerId={1}
                  handleUpdatePage={handleUpdatePage}
                  iframeScale={iframeScale}
               />
            </Box>

            <Box
               width='40%'
               sx={{
                  height: 1080 * iframeScale,
                  position: "relative",
                  mb: 4,
               }}
            >
               <CustomLeaderboardPreview
                  viewerId={2}
                  handleUpdatePage={handleUpdatePage}
                  iframeScale={iframeScale}
               />
            </Box>
         </Stack>

         <Table variant='dashboard' sx={{ maxWidth: 1500 }}>
            <TableHead>
               <TableRow>
                  {/* <TableCell>ID</TableCell> */}
                  <TableCell sx={{ pl: 4 }}>ID</TableCell>
                  <TableCell>Title</TableCell>
                  <TableCell>Players</TableCell>
                  <TableCell>Standings</TableCell>
                  <TableCell align='right' width='400'>
                     {parent != "producer" && (
                        <Button onClick={add} variant='contained' color='error'>
                           + ADD NEW
                        </Button>
                     )}
                  </TableCell>
               </TableRow>
            </TableHead>
            <TableBody>
               {all &&
                  all.map((row) => (
                     <React.Fragment key={"slideRow" + row.id}>
                        <TableRow>
                           <TableCell
                              width='80'
                              sx={{ textAlign: "center", fontWeight: "bold" }}
                           >
                              {row.id}
                           </TableCell>
                           <TableCell width='350' sx={{ pl: 4 }}>
                              <Stack spacing={1}>
                                 <TextInput
                                    color='#333'
                                    value={row.Title ? row.Title : "-NO TITLE-"}
                                    callback={(value) =>
                                       update(row.id, { Title: value })
                                    }
                                 />
                                 <SelectInput
                                    label='Column Rows'
                                    size='small'
                                    options={[4, 5, 6, 7, 8, 9, 10]}
                                    value={row.Rows}
                                    callback={(value) =>
                                       update(row.id, { Rows: value })
                                    }
                                 />
                                 <SelectInput
                                    label='Columns'
                                    size='small'
                                    value={row.Columns}
                                    options={[1, 2]}
                                    callback={(value) =>
                                       update(row.id, { Columns: value })
                                    }
                                 />
                                 {row.Columns < 2 && (
                                    <SelectInput
                                       label='Archetype'
                                       size='small'
                                       value={row.Archetype}
                                       options={[1, 0]}
                                       boolean={true}
                                       callback={(value) =>
                                          update(row.id, { Archetype: value })
                                       }
                                    />
                                 )}
                              </Stack>
                           </TableCell>
                           <TableCell sx={{ position: "relative" }}>
                              <Stack spacing={2}>
                                 <Button
                                    variant='outlined'
                                    color='warning'
                                    onClick={() => {
                                       editRow === row.id
                                          ? setEditRow()
                                          : setEditRow({
                                               Title: row.Title,
                                               id: row.id,
                                               PlayerIDs: row.PlayerIDs,
                                            });
                                    }}
                                 >
                                    Edit Players
                                 </Button>
                                 <Box
                                    sx={{
                                       width: "100%",
                                       maxHeight: 150,
                                       overflowY: "auto",
                                       overflowX: "hidden",
                                    }}
                                 >
                                    <b>
                                       Total Players:{" "}
                                       {JSON.parse(row.PlayerIDs).length}
                                    </b>
                                    {_.filter(allPlayers, (o) => {
                                       return row.PlayerIDs.includes(o.id);
                                    }).map((player) => {
                                       return (
                                          <Box
                                             key={player.id}
                                             sx={{ whiteSpace: "nowrap" }}
                                          >
                                             {player.LastName +
                                                ", " +
                                                player.FirstName}
                                          </Box>
                                       );
                                    })}
                                 </Box>
                              </Stack>
                           </TableCell>
                           <TableCell>
                              <StandingsSelect
                                 selected={
                                    row.StandingsId ? row.StandingsId : 0
                                 }
                                 callback={(e) =>
                                    handleChangeStandings(row.id, e)
                                 }
                              />
                           </TableCell>
                           <TableCell
                              align='right'
                              sx={{ whiteSpace: "nowrap" }}
                           >
                              <Stack direction='row' spacing={1}>
                                 {parent != "producer" && (
                                    <>
                                       <Button
                                          variant='outlined'
                                          color='success'
                                          fullWidth
                                          id={row.id}
                                          onClick={(e) => handleLoadSlide(1, e)}
                                       >
                                          LOAD 1
                                       </Button>

                                       <Button
                                          variant='outlined'
                                          color='success'
                                          fullWidth
                                          id={row.id}
                                          onClick={(e) => handleLoadSlide(2, e)}
                                       >
                                          LOAD 2
                                       </Button>

                                       <Button
                                          variant='outlined'
                                          color='error'
                                          fullWidth
                                          startIcon={<DeleteIcon />}
                                          onClick={() =>
                                             handleDelete(row.id, row.Title)
                                          }
                                       >
                                          DELETE
                                       </Button>
                                    </>
                                 )}
                              </Stack>
                           </TableCell>
                        </TableRow>
                     </React.Fragment>
                  ))}
            </TableBody>
         </Table>

         {editRow && (
            <Modal
               open={true}
               onClose={() => setEditRow(null)}
               aria-labelledby='modal-modal-title'
               aria-describedby='modal-modal-description'
            >
               <Box sx={style}>
                  <Button
                     aria-label='close'
                     variant='outlined'
                     color='secondary'
                     sx={{
                        position: "absolute",
                        top: 10,
                        right: 10,
                        zIndex: 100,
                     }}
                     startIcon={<CloseIcon />}
                     onClick={() => setEditRow(null)}
                  >
                     Close
                  </Button>

                  <Typography
                     variant='h5'
                     component='div'
                     sx={{
                        textTransform: "uppercase",
                     }}
                  >
                     Storyboards Players
                  </Typography>
                  <Typography
                     variant='h4'
                     component='div'
                     sx={{
                        textTransform: "uppercase",
                        mb: 2,
                     }}
                  >
                     {editRow.Title}
                  </Typography>

                  <Box>
                     <PlayerSelect
                        callback={(value) =>
                           update(editRow.id, { PlayerIDs: value })
                        }
                        selectedRows={editRow.PlayerIDs}
                        rows={allPlayers}
                     />
                  </Box>
               </Box>
            </Modal>
         )}

         {notification && (
            <NotificationWidget
               notification={notification.message}
               key={"notification" + notification.date}
            />
         )}
      </Wrapper>
   );
}
