import React, { useState, useContext, useEffect } from "react";
import { useParams } from "react-router-dom";
import { useTheme } from "@mui/material/styles";

import { motion, AnimatePresence } from "framer-motion";
import _ from "lodash";
import axios from "axios";

import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Stack";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";

import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableBody from "@mui/material/TableBody";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";

//HOOKS
import useControls from "../../hooks/useControls";

const deckType = (i, draftRounds, formatRounds, formatLetter, colors) => {
   let letter = "";

   if (i <= draftRounds) {
      letter = "D";
   }

   if (i > draftRounds && i <= formatRounds + draftRounds) {
      letter = formatLetter;
   }

   return (
      <Stack
         sx={{
            backgroundColor: colors.background.dark,
            color: colors.text.white,
            borderRadius: 100,
            width: 35,
            height: 35,
            fontSize: 24,
            pl: 0.3,
            pt: 0.2,
            mr: 0,
            ml: "auto",
         }}
         width='100%'
         alignItems='center'
         justifyContent='center'
      >
         <Box>{letter}</Box>
      </Stack>
   );
};

export default function RoundByRoundDay({
   day,
   draftRounds,
   formatRounds,
   roundsPerDay,
   formatLetter,
   results,
}) {
   const theme = useTheme();
   console.log(results);

   return (
      <>
         {results.length > 0 && (
            <Stack direction='row'>
               <Typography
                  variant='h3'
                  component='div'
                  sx={{
                     textTransform: "uppercase",
                     textAlign: "right",
                     width: 160,
                     pr: 4,
                  }}
               >
                  DAY {day}
               </Typography>
               <Table variant='roundbyround'>
                  <TableHead>
                     <TableRow>
                        <TableCell width='70'></TableCell>
                        <TableCell width='70'></TableCell>
                        <TableCell width='35%'>OPPONENT</TableCell>
                        <TableCell>ARCHETYPE</TableCell>
                        <TableCell width='15%'>RESULTS</TableCell>
                     </TableRow>
                  </TableHead>
                  <TableBody>
                     {[...Array(roundsPerDay).keys()].map((row, i) => (
                        <TableRow key={"row" + i + 1}>
                           <TableCell>
                              {deckType(
                                 i + 1,
                                 draftRounds,
                                 formatRounds,
                                 formatLetter,
                                 theme.palette
                              )}
                           </TableCell>
                           <TableCell align='center'>
                              {i + 1 + roundsPerDay * (day - 1)}
                           </TableCell>
                           <TableCell>
                              {results[i] && results[i].Opponent && (
                                 <>
                                    {results[i].Opponent.FirstName}{" "}
                                    {results[i].Opponent.LastName}
                                 </>
                              )}
                           </TableCell>
                           <TableCell>
                              {results[i] && results[i].OpponentDeck && (
                                 <>
                                    {i >= draftRounds &&
                                       results[i].OpponentDeck.Archetype}
                                 </>
                              )}
                           </TableCell>
                           <TableCell>
                              {results[i] && <>{results[i].ShortResult}</>}
                           </TableCell>
                        </TableRow>
                     ))}
                  </TableBody>
               </Table>
            </Stack>
         )}
      </>
   );
}
