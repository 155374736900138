import { useState, useMemo, useContext, useEffect, useRef } from "react";
import { SocketContext } from "../context/socket";

import axios from "axios";
import _ from "lodash";

const SERVER_URL = process.env.REACT_APP_SERVER_URL;

export default function useFeatureCards() {
   const socket = useContext(SocketContext);

   const [entries, setEntries] = useState([]);
   const [allCards, setAllCards] = useState([]);

   const [activeData, setActiveData] = useState();
   const [show, setShow] = useState(true);

   const currentIdRef = useRef();

   useEffect(() => {
      handlers.getEntries();
      handlers.getAllCards();
   }, []);

   const handlers = useMemo(
      () => ({
         //CONTROLS
         viewerNextPrev: (action) => {
            socket.emit(
               "featureCardsViewerNextPrev",
               action,
               currentIdRef.current
            );
         },
         viewerLoad: (id) => {
            currentIdRef.current = id;
            socket.emit("featureCardsViewerLoad", id);
         },
         viewerShowHide: (action) => {
            socket.emit("featureCardsViewerShowHide", action);
         },

         //ENTRIES
         getEntries: () => {
            socket.emit("getFeatureCardEntries", (response) => {
               setEntries(response);
            });
         },
         get: (id) => {
            socket.emit("getFeatureCardEntry", id, (response) => {
               setActiveData(response);
            });
         },
         addEntry: () => {
            socket.emit("addFeatureCardEntry", (response) => {
               setEntries(response);
            });
         },
         deleteEntry: (entryId) => {
            if (
               window.confirm(
                  "Are you sure you want to delete this Card Group?"
               ) == true
            ) {
               socket.emit("deleteFeatureCardEntry", entryId, (response) => {
                  setEntries(response);
               });
            } else {
               return;
            }
         },
         deleteCard: (entryId, id) => {},
         updateEntry: (entryId, update) => {
            socket.emit(
               "updateFeatureCardEntry",
               entryId,
               update,
               (response) => {
                  setEntries(response);
               }
            );
         },
         //ALL CARDS
         getAllCards: () => {
            socket.emit("getAllCardNames", (response) => {
               setAllCards(response);
            });
         },

         //SUBSCRIBE
         subscribeViewer: (tableId) => {
            socket.emit("subscribeFeatureCards", (response) => {
               console.log(response);
            });

            socket.on("featureCardsViewerLoad", (id) => {
               handlers.get(id);
               currentIdRef.current = id;
            });

            socket.on("featureCardsViewerNextPrev", (action) => {
               handlers.viewerNextPrev(action);
            });

            socket.on("featureCardsViewerShowHide", (action) => {
               switch (action) {
                  case "show":
                     setShow(true);
                     break;
                  case "hide":
                     setShow((prevState) => {
                        return false;
                     });
                     break;
                  case "toggle":
                     setShow((prevState) => {
                        return !prevState;
                     });
                     break;
               }
            });
         },
      }),
      []
   );

   return [entries, allCards, activeData, show, handlers];
}
